<template>
  <MPopover
    is-hover
    position="bottom-end"
    class-name="m-header-center__popover"
    :open="popoverCartShow"
    :disable="true"
    @close="popoverCartShow = false"
  >
    <template #activator>
      <NavItem
        :to="getUrl('cart.php', false)"
        :label="$t('molecules.header.cart')"
        :badge="cartProductsQuantity"
        icon="i-cart-header"
        no-prefetch
      />
    </template>
    <template #content>
      <div class="m-header-center__bar-offer">
        <div class="content">
          <Suspense>
            <NuxtLazyHydrate when-visible>
              <LazyMProductHeaderCart
                v-for="(item, index) in cartProducts"
                :key="index"
                :item="item.product"
                @remove:cart="removeCart(item.id)"
              />
            </NuxtLazyHydrate>
          </Suspense>
        </div>
<!--        <Suspense>-->
<!--          <NuxtLazyHydrate when-visible>-->
<!--            <LazyOBottomBar btn animate>-->
<!--              <div v-if="total?.amount">-->
<!--                <LazyAPrice-->
<!--                  :regular-price="total?.amount"-->
<!--                  :currency="total?.currencyCodeIso"-->
<!--                  size="sm"-->
<!--                />-->
<!--              </div>-->
<!--              <RouterLink to="/cart.php">-->
<!--                <AButton variant="success">-->
<!--                  {{ $t("organisms.tabbar.cart") }}-->
<!--                </AButton>-->
<!--              </RouterLink>-->
<!--            </LazyOBottomBar>-->
<!--          </NuxtLazyHydrate>-->
<!--        </Suspense>-->
      </div>
    </template>
  </MPopover>
</template>

<script setup lang="ts">
import { useNavigate } from '@/composables/useNavigate'
import NavItem from './HeaderNavItem.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'
import { useCartStore } from '@/stores/cart'
import { defineAsyncComponent } from 'vue'

const LazyMProductHeaderCart = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/molecules/ProductHeaderCart/MProductHeaderCart.vue')
)

const { getUrl } = useNavigate()
const {
  cartProductsQuantity,
  cartProducts,
  popoverCartShow,
  removeProductFromCart
} = useCartStore()

function removeCart (id: number) {
  if (id) {
    removeProductFromCart([id])
  }
}
</script>
