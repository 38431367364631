<template>
 <LazyTestModeBanner v-if="currentRegion.isTestMode && !isNotificationShow" />

  <RenderCacheable
    :max-age="3600"
    :async-data-keys="['home-notification']"
  >
   <HomeNotificationBanner />
  </RenderCacheable>

  <div class="theme-default">
    <RenderCacheable
      :max-age="3600"
      :cache-tags="[route.fullPath]"
      :async-data-keys="['shops-has-any', 'cities-has-any', 'cities']"
    >
      <HeaderBar />
    </RenderCacheable>

    <HeaderWrapper />

    <RenderCacheable
      :max-age="3600"
      :cache-tags="[route.fullPath]"
      :async-data-keys="['header-categories']"
    >
      <HeaderCategories />
    </RenderCacheable>

    <div class="page-content">
      <slot />
    </div>

   <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
     <RenderCacheable
       v-if="blogVisibility"
       class="o-blog"
       :max-age="3600"
       :async-data-keys="['blog-cache']"
     >
       <LazyOBlog />
     </RenderCacheable>
   </NuxtLazyHydrate>

    <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
      <RenderCacheable tag="footer" class="footer" :async-data-keys="['shops']">
        <OFooter />
      </RenderCacheable>
    </NuxtLazyHydrate>

    <ClientOnly>
     <NotificationWrapper v-slot="item">
       <Notification
          :item="item"
          :theme="darkTheme"
          style="/* stylelint-disable -- empty-line-before */ --nv-width: 500px"
       />
     </NotificationWrapper>

      <ModalWrapper />
      <SidebarWrapper />
      <OTabBar />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { darkTheme, Notification, Notivue as NotificationWrapper } from 'notivue'
import { useContextData } from '#imports'
import { useConfig } from '@/stores/config'

import OTabBar from '@/components/organisms/TabBar/OTabBar.vue'
import OFooter from '@/components/organisms/Footer/OFooter.vue'
import HeaderWrapper from '@/components/business/header/HeaderWrapper'
import HeaderCategories from '@/components/business/header/HeaderCategories.vue'
import HeaderBar from '@/components/business/header/HeaderBar.vue'
import HomeNotificationBanner from '@/components/business/home/HomeNotificationBanner.vue'

const LazyTestModeBanner = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/molecules/Notification/TestModeBanner.vue'))
const LazyOBlog = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/organisms/Blog/OBlog.vue'))

const route = useRoute()
const { currentRegion } = useConfig()

const isNotificationShow = useContextData('home-notification-show')

// const blogVisibility = computed(() => {
//   const routeName = route.name?.toString() ?? ''
//   return (routeName.includes('index') || routeName.includes('category')) && isMscRegion.value
// })
const blogVisibility = ref(false)
</script>

<style lang="postcss">
.theme-default {
  --primary: var(--color-red-400);
  --primary-content: var(--color-white);
  --primary-focus-dark: var(--color-red-800);
  --primary-focus-dark-content: var(--color-white);
  --primary-pressed-dark: var(--color-red-900);
  --primary-focus-middle: var(--color-red-100);
  --primary-focus-light: var(--color-red-50);
  --primary-page: var(--color-neutral-50);
  --scrollbar-thumb-color: rgb(42 36 37 / 32%);
}

*:not(html, body) {
  /* old firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) transparent;

  @media (--screen-lg) {
    &::-webkit-scrollbar {
      width: var(--spacer-4xs);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-xs);
      background-color: var(--scrollbar-thumb-color);
    }
  }
}

/* .modal-open .popover {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
} */

.Notivue__content-message {
  overflow-wrap: anywhere;
}

.header__center {
  position: sticky;
  top: 0;
  z-index: 102;
}

.page-content {
  min-height: calc(100vh - 350px);
}

@media (--screen-xs) {
  .page-bottom-bar {
    & .footer {
      padding-bottom: 3.5rem;
    }

    &--product .footer {
      padding-bottom: var(--spacer-6xl);

      &.footer--big-bottom-bar {
        padding-bottom: 6.5rem;
      }
    }
  }
}
</style>
